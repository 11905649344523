<svg
  {...$$props}
  width="189"
  height="189"
  viewBox="0 0 189 189"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g id="paths" clip-path="url(#clip0_753_289)" style="stroke: var(--logo-color, #EB5E28)">
    <path
      d="M43.7937 12.5298C0.57938 44.8609 0.342815 92.8076 16.3236 126.458C33.6361 162.913 75.9226 184.525 117.769 173.613C156.42 163.534 183.82 122.549 182.358 95.8221"
      stroke-width="12"
      stroke-linecap="round" />
    <path
      d="M78.6923 75.6945C50.8177 86.6612 44.0155 112.559 47.9751 133.172C52.2646 155.502 72.1284 173.572 96.2818 173.985C118.59 174.366 139.116 156.332 142.045 141.655"
      stroke-width="12"
      stroke-linecap="round" />
    <path
      d="M43.5856 12.4047C17.9702 31.569 17.83 59.9895 27.3026 79.936C37.5646 101.545 62.6301 114.355 87.4348 107.887C110.345 101.913 126.587 77.6188 125.72 61.7764"
      stroke-width="12"
      stroke-linecap="round" />
  </g>
  <defs>
    <clipPath id="clip0_753_289">
      <rect width="189" height="189" fill="white" />
    </clipPath>
  </defs>
</svg>
